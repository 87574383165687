/* global Component, axios */
class redirectPaymentComponent extends Component {

    getProps() {
        return ['PayTermObj', 'SO'];
    }

    static name() {
        return "redirectPaymentComponent";
    }

    static componentName() {
        return "redirectPaymentComponent";
    }

    data(){
        return {
            text : '',
            errorMsg: ''
        };
    }

    getTemplate() {
        return `
        <button class="btn btn-primary" type="button" disabled>
            <div v-if='!errorMsg'>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{text}}
            </div>
            <span v-else>{{tr(errorMsg)}}</span>
        </button>`;
    }

    mounted(){
        return async function () {
            this.text = this.tr(["Redirecting to {{$1}}",this.PayTermObj.fields.PayTermName]);
            let res = await axios.post(this.PayTermObj.fields.OppenRedirectEndPoint, {
                SerNr: this.SO
            });
            if (!res.data.ok || !res.data.url) this.errorMsg = res.res || 'There was an error generating the Payment';
            else window.open(res.data.url, '_self');
        };
    }

}

redirectPaymentComponent.registerComponent();